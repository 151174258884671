import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField, Typography } from '@mui/material';
import Axios from 'axios';
import { formatHandleError } from '../../../helpers/formatData';

function ProductsTagAutocomplete({
  handleOnChange = () => {},
  selectedValue,
  label,
  disabled,
  error,
  variant,
  className,
  setSnackbar,
}) {
  const [autocompleteValues, setAutocompleteValues] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getAutoCompleteValues = async () => {
      setLoading(true);
      try {
        const response = await Axios.get('/catalog/product-tag-list');
        setAutocompleteValues(response.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao buscar as tags',
          error: err,
        });
      }
    };

    getAutoCompleteValues();
  }, []);
  return (
    <Autocomplete
      loading={loading}
      size="small"
      options={autocompleteValues}
      getOptionLabel={(option) => option}
      isOptionEqualToValue={(option, value) => option === value}
      renderOption={(props, option) => {
        const { key, ...restProps } = props;
        return (
          <Typography key={key} {...restProps} variant="caption">
            {option}
          </Typography>
        );
      }}
      disabled={disabled}
      value={selectedValue}
      onChange={handleOnChange}
      noOptionsText="Opção não encontrada"
      renderInput={(params) => (
        <TextField {...params} label={label} variant={variant} error={error} />
      )}
      className={className}
    />
  );
}

export default ProductsTagAutocomplete;
