import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { numberToReal } from '../../../../../../../../helpers/formatData';

const styles = StyleSheet.create({
  mainView: {
    rowGap: 2,
  },
  condition: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 1,
  },
  paymentMethod: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 1,
  },
  prices: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  pagination: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  smallText: {
    fontSize: 9,
  },
  priceText: {
    fontWeight: 'bold',
  },
  line: {
    width: 300,
    flexDirection: 'row',
    borderBottomColor: '#000',
    borderBottomWidth: 1,
    height: 24,
    fontSize: 12,
    fontStyle: 'italic',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

function Footer({ order, pageNumber, pageQuantity }) {
  const totalProducts = order.items
    .map((item) => item.quantity * item.selectedPrice)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const paymentsDiscounts = order.payments
    .filter((payment) => payment.type === 'Recebimento')
    .map((payment) => payment.decreaseValue || 0)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  return (
    <View style={styles.mainView}>
      <View style={styles.condition}>
        <Text style={styles.smallText}>
          Condição:{' '}
          {order.payments?.[0]?.condition === 'A PRAZO'
            ? `${order.payments[0].condition} (mensalista)`
            : 'A VISTA'}
        </Text>
        <Text style={styles.smallText}>Frete: R$ {numberToReal(order.shipping.price)}</Text>
      </View>
      <View style={styles.paymentMethod}>
        <Text style={styles.smallText}>
          Pagamento: {order.payments?.[0]?.method || 'AGUARDANDO'}
        </Text>
      </View>
      <View style={styles.prices}>
        <Text style={styles.smallText}>Produtos: R$ {numberToReal(totalProducts)}</Text>
        {paymentsDiscounts ? (
          <Text style={styles.smallText}>
            Desconto no pagamento: R$ {numberToReal(paymentsDiscounts)}
          </Text>
        ) : null}
        <Text style={styles.priceText}>
          Total: R$ {numberToReal(order.orderTotal - paymentsDiscounts)}
        </Text>
      </View>
      <View style={styles.line} />
      <View style={styles.pagination}>
        <Text style={styles.smallText}>
          Página: {pageNumber}/{pageQuantity}
        </Text>
      </View>
    </View>
  );
}

export default Footer;
