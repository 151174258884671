import { Autocomplete, TextField, Typography, createFilterOptions } from '@mui/material';
import React, { useEffect, useState, memo } from 'react';
import { getUser, searchUsers } from '../../../services/melhorGestao/users';

function UserAutocomplete({
  setSnackbar = () => {},
  handleOnChange = () => {},
  selectedUser,
  setSelectedUser = () => {},
  size = 'small',
  label = 'Usuário',
  disabled,
  error,
  variant,
  roles,
  className,
}) {
  const [searchText, setSearchText] = useState('');
  const [usersList, setUsersList] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [loadingUsersList, setLoadingUsersList] = useState(false);
  const [timeoutUserSearchId, setTimeoutUserSearchId] = useState();

  const handleSearchAllUsers = async () => {
    searchUsers({
      searchText,
      setLoadingUsersList,
      setUsersList,
      setSnackbar,
      roles,
    });
  };

  const filterUser = createFilterOptions({
    stringify: (option) =>
      option.name + option.email + option.userId + option.documents.cpf + option.documents.cnpj,
  });

  useEffect(() => {
    setLoadingUsersList(true);
    if (timeoutUserSearchId) {
      clearTimeout(timeoutUserSearchId);
    }
    setTimeoutUserSearchId(setTimeout(() => handleSearchAllUsers(), 1000));
  }, [searchText]);

  useEffect(() => {
    const fetchData = async () => {
      if (typeof selectedUser === 'object') {
        setSelectedUser(selectedUser);
        return setSelectedValue(selectedUser);
      }
      if (typeof selectedUser === 'string') {
        if (selectedUser) {
          const user = await getUser(selectedUser);
          setSelectedUser(user);
          return setSelectedValue(user);
        }
      }
      setSelectedUser(null);
      return setSelectedValue(null);
    };
    fetchData();
  }, [selectedUser]);

  return (
    <Autocomplete
      size={size}
      options={usersList}
      filterOptions={filterUser}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.userId === value.userId}
      renderOption={(props, option) => {
        const { key, ...restProps } = props;
        return (
          <Typography key={option.userId} {...restProps} variant="caption">
            {option.userId} - {option.name}
          </Typography>
        );
      }}
      disabled={disabled}
      value={selectedValue}
      onChange={handleOnChange}
      noOptionsText="Opção não encontrada"
      onInputChange={(event, newInputValue) => {
        setSearchText(newInputValue);
      }}
      loading={loadingUsersList}
      renderInput={(params) => (
        <TextField {...params} label={label} variant={variant} error={error} />
      )}
      className={className}
    />
  );
}

export default memo(UserAutocomplete);
