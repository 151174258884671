/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { memo, useEffect, useState } from 'react';
import { Box, Button, Grid, IconButton, Typography, Snackbar, Tooltip, Alert } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CropFreeIcon from '@mui/icons-material/CropFree';
import GetAppIcon from '@mui/icons-material/GetApp';
import HttpIcon from '@mui/icons-material/Http';
import CopyrightIcon from '@mui/icons-material/Copyright';
import HeightIcon from '@mui/icons-material/Height';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import CustomInput from '../../../CustomInput';
import SimpleDialog from '../../../Common/SimpleDialog';
import DialogMovement from './DialogMovement';
import { isEAN } from '../../../../services/melhorGestao/validator';
import SimpleImage from '../../../Common/SimpleImage';
import DialogProductImage from '../../Dialogs/DialogProductImages';
import TagsAutocomplete from '../../../Common/TagsAutocomplete';

const useStyles = makeStyles((theme) => ({
  inputAdornmentWidth: {
    transform: 'rotateZ(90deg)',
  },
  inputAdornmentLength: {
    transform: 'rotateZ(45deg)',
  },
  minGridSize: {
    minWidth: 600,
  },
  smallTypography: {
    fontSize: '0.85rem',
  },
  boxImage: {
    cursor: 'pointer',
  },
  images: {
    height: 'auto',
    margin: 7,
    boxShadow:
      'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
  },
  buttonAddImage: {
    width: 140,
    top: -17,
    left: -40,
    cursor: 'pointer',
  },
  typographyAddImage: {
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
}));

function InformationTab({ productForm, handleChange, setProductForm, formChanged }) {
  const classes = useStyles();

  const [openDialogDescription, setOpenDialogDescription] = useState(false);
  const handleOpenDialogDescription = () => setOpenDialogDescription(true);
  const handleCloseDialogDescription = () => setOpenDialogDescription(false);

  const [openDialogMovement, setOpenDialogMovement] = useState(false);
  const handleOpenDialogMovement = () => setOpenDialogMovement(true);
  const handleCloseDialogMovement = () => setOpenDialogMovement(false);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleChangeDescription = (event) => {
    const fieldValue = event.target.value;

    setProductForm((oldFields) => ({
      ...oldFields,
      description: fieldValue,
      reviews: {
        ...oldFields.reviews,
        description: fieldValue.length < 200,
      },
    }));
  };

  const handleChangePackage = (event) => {
    const fieldValue = event.target.value;
    const fieldName = event.target.name;

    setProductForm((oldFields) => ({
      ...oldFields,
      package: {
        ...oldFields.package,
        [fieldName]: parseFloat(fieldValue) || 0,
      },
      reviews: {
        ...oldFields.reviews,
        [fieldName]: !parseFloat(fieldValue),
      },
    }));
  };

  const handleChangeTags = (_, value) => {
    const formatString = (str) => str.toLowerCase().replace(/[\W_]/g, '');

    const distritamIndex = value.findIndex(
      (tag) => formatString(tag) === formatString('Distritam'),
    );
    const cosmexIndex = value.findIndex((tag) => formatString(tag) === formatString('Cosmex'));

    if (distritamIndex !== -1 && cosmexIndex !== -1) {
      if (distritamIndex < cosmexIndex) {
        value.splice(distritamIndex, 1);
      } else {
        value.splice(cosmexIndex, 1);
      }
    }

    setProductForm((oldFields) => ({
      ...oldFields,
      tags: value,
    }));
  };

  const [productImages, setProductImages] = useState(null);

  const handleOpenDialogProductImages = () => {
    if (productForm.images && productForm.images.length > 0) {
      setProductImages(productForm);
    }
  };

  const handleCloseDialogProductImages = () => setProductImages(null);

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [validEan, setValidEan] = useState(true);
  const [timeoutValidatorEanId, setTimeoutValidatorEanId] = useState(false);
  const [loadingValidatorEan, setLoadingValidatorEan] = useState(false);

  useEffect(() => {
    if (formChanged) {
      setLoadingValidatorEan(true);
      if (timeoutValidatorEanId) {
        clearTimeout(timeoutValidatorEanId);
      }
      setTimeoutValidatorEanId(
        setTimeout(async () => {
          const resultEAN = await isEAN(productForm.othersIds.gtin);
          setValidEan(resultEAN);

          setLoadingValidatorEan(false);
        }, 1000),
      );
    }
  }, [productForm.othersIds.gtin]);

  return (
    <Grid container direction="column" spacing={3}>
      {snackbar.open && (
        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      )}
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Informações</Typography>
            {productForm.categories && productForm.categories[0]
              ? productForm.categories.map((category, index) => {
                  if (index === 0) {
                    return category.name;
                  }
                  return ` > ${category.name}`;
                })
              : 'Nenhuma categoria foi selecionada'}
          </Grid>
          <Grid item onClick={handleOpenDialogProductImages}>
            <Box
              bgcolor="#fff"
              width="70px"
              height="70px"
              position="absolute"
              right="20px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              boxShadow="0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
              className={classes.boxImage}
            >
              {productForm.images && productForm.images.length > 0 ? (
                <SimpleImage
                  src={productForm.images[0].src}
                  height={70}
                  width={70}
                  imgHeight={70}
                  imgWidth={70}
                  alt={productForm.name}
                />
              ) : (
                <SimpleImage
                  src="/assets/imgs/no_image.png"
                  imgHeight={30}
                  imgWidth={30}
                  alt={productForm.name}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <CustomInput name="name" label="Nome" value={productForm.name} onChange={handleChange} />
      </Grid>
      <Grid item>
        <Box position="relative">
          <CustomInput
            name="technicalName"
            label="Nome técnico"
            value={productForm.technicalName}
            onChange={handleChange}
          />
        </Box>
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item xs={6}>
            <CustomInput
              name="localization"
              value={productForm.localization || ''}
              label="Localização do Estoque"
              onChange={handleChange}
              InputAdornmentIcon={EditLocationIcon}
            />
          </Grid>

          <Grid item xs={6}>
            <CustomInput
              disabled
              value={productForm.slug}
              label="URL"
              InputAdornmentIcon={HttpIcon}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item xs={6}>
            <CustomInput
              name="gtin"
              label="Código de Barras"
              value={productForm.othersIds.gtin}
              error={!validEan}
              onChange={handleChange}
              InputAdornmentIcon={CropFreeIcon}
              InputEndAdornment={
                productForm.othersIds.gtin ? (
                  <Tooltip
                    placement="right"
                    title={<Typography align="center">Baixar imagem do código</Typography>}
                  >
                    <IconButton
                      size="small"
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_REST_URL_IMG}/imagens/barcode/${productForm.othersIds.gtin}?text=${productForm.technicalName}&dpi=500`,
                        )
                      }
                    >
                      <GetAppIcon />
                    </IconButton>
                  </Tooltip>
                ) : null
              }
              loading={loadingValidatorEan}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomInput
              name="brand"
              label="Marca"
              value={productForm.brand}
              onChange={handleChange}
              InputAdornmentIcon={CopyrightIcon}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item xs={6} sm={3}>
            <CustomInput
              name="height"
              label="Altura (cm)"
              value={productForm.package.height || ''}
              onChange={handleChangePackage}
              InputAdornmentIcon={HeightIcon}
              error={productForm.reviews.height}
              number
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <CustomInput
              name="width"
              label="Largura (cm)"
              value={productForm.package.width || ''}
              onChange={handleChangePackage}
              InputAdornmentIcon={HeightIcon}
              InputAdornmentStyle={classes.inputAdornmentWidth}
              error={productForm.reviews.width}
              number
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <CustomInput
              name="length"
              label="Comprimento (cm)"
              value={productForm.package.length || ''}
              onChange={handleChangePackage}
              InputAdornmentIcon={HeightIcon}
              InputAdornmentStyle={classes.inputAdornmentLength}
              error={productForm.reviews.length}
              number
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <CustomInput
              name="weight"
              label="Peso (kg)"
              value={productForm.package.weight || ''}
              onChange={handleChangePackage}
              error={productForm.reviews.weight}
              number
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between" spacing={2} alignItems="center">
          <Grid item xs={12}>
            <TagsAutocomplete
              type="products"
              handleOnChange={handleChangeTags}
              selectedValue={productForm.tags}
              setSnackbar={setSnackbar}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <CustomInput
          name="sellerObs"
          label="Observações para o vendedor"
          value={productForm.sellerObs || ''}
          onChange={handleChange}
          variant="outlined"
          rows={2}
        />
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between" spacing={1}>
          <Grid item>
            <Button variant="outlined" onClick={handleOpenDialogDescription}>
              Descrição
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={handleOpenDialogMovement}>
              Movimentação
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {openDialogMovement && (
        <DialogMovement
          openDialogMovement={openDialogMovement}
          product={productForm}
          handleCloseDialogMovement={handleCloseDialogMovement}
        />
      )}

      {openDialogDescription && (
        <SimpleDialog
          content={
            <Grid className={classes.minGridSize}>
              <CustomInput
                name="description"
                label="Descrição"
                rows={20}
                value={productForm.description || ''}
                onChange={handleChangeDescription}
                helperText={
                  productForm.reviews.description
                    ? `Digite no mínimo 200 caracteres (${productForm.description.length})`
                    : productForm.description.length
                }
                error={productForm.reviews.description}
              />
            </Grid>
          }
          dialogTitle="Descrição em texto"
          dialogText="Altere abaixo a descrição do produto"
          handleClose={handleCloseDialogDescription}
          openDialog={openDialogDescription}
        />
      )}

      {productImages ? (
        <DialogProductImage
          selectedProduct={productImages}
          openDialogProductImages={!!productImages}
          handleCloseDialogProductImages={handleCloseDialogProductImages}
        />
      ) : null}
    </Grid>
  );
}

export default memo(InformationTab);
